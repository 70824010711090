import React from 'react';
import { Container } from 'shared/components';

export const Header = () => {
  return (
    <header className="w-full py-12 bg-Floral">
      <Container>
        <div className="flex justify-between" style={{ bottom: '30px', position: 'relative' }}>
          {/* Replace h2 with img tag for logo */}
          <img 
            src="/assets/images/image-12.png" 
            alt="2read logo" 
            className="h-5 md:h-8"  // Adjust size for mobile (h-10) and website (h-16)
          />
          
          {/* Adjust Blog link for responsiveness */}
          <h2 className="font-LeagueSpartan text-[#DA7756]">
            <a 
              href="https://substack.com/@1truejishnu?r=4n4kx8&utm_campaign=profile&utm_medium=profile-page" 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label="2read app medium blog link"
              className="text-sm md:text-2xl hover:underline font-medium"  // Adjust text size for mobile (text-sm) and larger screens (md:text-lg)
            >
              Blog
            </a>
          </h2>
        </div>
      </Container>
    </header>
  );
};
