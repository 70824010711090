import { Container } from 'shared/components';

export const ReadingSection = () => {
  return (
    <section className="py-20 md:py-28 bg-PowderPink">
      <Container>
        <div className="flex justify-between text-justify">
          <div className="w-full md:w-[44%]">
            <h2 className="text-Mulberry font-medium font-LeagueSpartan text-3xl md:text-6xl">
              Reading a book isn't the end; reflection elevates it.
            </h2>
            <br />
            <div className="w-full flex justify-center mb-7">
              <div className="w-40 rotate-6 block md:hidden">
                <img
                  className="max-w-full h-auto"
                  src="/assets/images/mobile-1.png"
                  alt="Reading Mobile"
                />
              </div>
            </div>
            <div className="text-base md:text-3xl text-Mulberry font-thin font-LeagueSpartan">
              <p>
                The Daily Review feature facilitates consistent engagement with
                your collected highlights and notes.
              </p>
              <br />
              <p>
                It prompts daily revisits to reinforce learning, aiding in
                better retention and comprehension of valuable insights gleaned
                from your readings.
              </p>
              <br />
              <p>
                <strong>Streak calendar:</strong> See weekly consistency. <br />
                <strong>Streak counter:</strong> Track daily usage streaks.
              </p>
            </div>
          </div>
          <div className="flex flex-1 justify-center">
            <div className="w-80 rotate-6 hidden md:block">
              <img
                className="max-w-full h-auto"
                src="/assets/images/mobile-1.png"
                alt="Reading Mobile"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
