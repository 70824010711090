import React, { useState } from 'react';

const questions = [
  {
    question: ' What is Kindle Highlights Sync, and how does it work?',
    answer:
      'Kindle Highlights Sync automatically gathers all of your favorite highlights from Kindle books, presenting them in an organized manner within the app. This feature enables easy access and review of your Kindle highlights, enhancing your reading experience.',
  },
  {
    question: 'Is the app free to use, or does it require a subscription?',
    answer:
      "The app offers full access to all its features. You start with 5 free AI credits to explore the AI capabilities. To continue using AI features, you'll need to purchase additional AI credits. There are three one-time payment options: Learner for $4.88, Scholar for $9.88, and Maven for $19.88—choose based on your usage. AI credits never expire. In summary, the app has no subscriptions; AI credits are a one-time purchase, while all other features remain free for life. The AI credits are non-refundable and can not be transferred.",
  },
  {
    question: 'What are AI Credits and how do they work?',
    answer:
      "AI Credits are tokens that act as a special currency to access the app's AI-powered features, such as WordWise, AI Highlight Insight, and AI Summaries. Each use of an AI feature costs 1 credit, which is automatically deducted from your account when you click the feature. Your remaining credits are displayed in your account, and while plans come with a set number of free credits, you can purchase more as needed.",
  },
  {
    question: 'What do WordWise, AI Highlight Insights, and AI Summary do?',
    answer:
      "WordWise allows you to select a word or phrase from your Kindle highlights, and the AI provides both a general definition and a context-specific explanation based on the book—like having a smart dictionary tailored to your reading. AI Highlight Insights helps you rediscover the significance of your highlights by offering additional context, examples, and deeper explanations, enriching your understanding of the text. AI Summary offers two types of summaries based on your highlights: a general summary that provides a comprehensive overview, or a custom summary where you can specify a topic, and the AI will craft a detailed summary based on your request. You can also save every summary for later reference and generate multiple summaries for each book.",
  },
  {
    question: 'How secure is my data within the app?',
    answer:
      "Amazon credentials are solely utilized for login purposes, ensuring they aren't stored or shared with the app owner or any third parties. No data is shared with third parties, and it's not utilized for any personalized experiences.",
  },
  // {
  //   question: 'How can I cancel my subscription?',
  //   answer:
  //     'To cancel your subscription, you can manage it directly through your App store or GooglePlay store account settings or within the app, ensuring a hassle-free cancellation process.',
  // },
];

export const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(-1);

  const handleCollapse = (index: number) => () => {
    if (index === openIndex) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <section className="bg-RedishOrange py-20 px-7 md:px-20">
      <h1 className="text-OrangeYellow text-3xl md:text-5xl font-LeagueSpartan font-medium mb-9">
        Frequently Asked Questions
      </h1>
      <div className="max-w-[1180px] w-full flex flex-col gap-4">
        {questions.map((question, index) => {
          const expand = index === openIndex;
          return (
            <div key={index}>
              <div className="flex gap-5 text-base md:text-3xl font-LeagueSpartan">
                <h3
                  className={`text-white hover:text-OrangeYellow flex-1 flex items-center`}
                >
                  {question.question}
                </h3>
                <svg
                  onClick={handleCollapse(index)}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-7 h-7 md:w-14 md:h-14 cursor-pointer transition-all ease-in duration-300 ${
                    expand ? 'rotate-90' : 'rotate-0'
                  }`}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.9394 12.0001L8.46973 6.53039L9.53039 5.46973L16.0607 12.0001L9.53039 18.5304L8.46973 17.4697L13.9394 12.0001Z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </div>
              <div
                className={`overflow-hidden transition-[max-height] duration-500 ease-in ${
                  expand ? 'max-h-50' : 'max-h-0 h-0'
                }`}
              >
                <p className="text-xl max-w-[1050px] text-justify font-LeagueSpartan font-medium py-4 text-gray800">
                  {question.answer}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
