import { Banner, Container } from 'shared/components';
import { ReadingSection } from './components/ReadingSection';
import { RethinkSection } from './components/RethinkSection';
import { ReadProgressSection } from './components/ReadProgressSection';
import { NoteTakingSection } from './components/NoteTakingSection';
import { FAQ } from './components/FAQ';
import { Footer } from 'shared/components/layout/Footer';

const Home = () => {
  return (
    <div className="bg-Floral">
      <Banner />
      <Container>
        <div className="w-full h-[1px] bg-black opacity-20 mb-2 text-justify" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 auto-rows-auto mb-9 text-justify">
          <div className="p-5 flex flex-col lg:border-r">
            <div className="h-24 w-full">
              <div className="flex items-center">
                <img
                  width={36}
                  height={35}
                  src="/assets/images/method.png"
                  alt="Methods"
                />
                <h4 className="text-2xl text-Irish font-LeagueSpartan">
                  method
                </h4>
              </div>
            </div>
            <div className="flex-1">
              <div className="mb-4 flex justify-center items-center text-white font-LeagueSpartan text-lg font-medium w-9 h-9 rounded-full bg-Irish">
                1
              </div>
              <h2 className="text-Irish font-bold md:font-semibold text-2xl md:text-3xl">
                Import Kindle Highlights
              </h2>
              <br />
              <div className="text-xl md:text-2xl text-Irish font-thin font-LeagueSpartan Enhance reading with WordWise, Highlight Insights, and AI Summary.">
                <p>
                  Sync your highlights and notes seamlessly across devices,
                  whether you're using a Kindle device or the Kindle app.
                </p>
                <br />
                <p>
                  Tip: Additionally, you can convert PDFs into Kindle format,
                  enabling you to read, highlight, and add notes effortlessly.{' '}
                </p>
              </div>
            </div>
            <div className="w-full h-20 md:h-32" />
            <div className="flex justify-center items-center w-full">
              <img
                className="w-24 md:w-36"
                src="/assets/images/13.png"
                alt="Book"
              />
            </div>
          </div>
          <div className="p-5 flex flex-col lg:border-r">
            <div className="h-8 md:h-24 w-full" />
            <div className="flex-1">
              <div className="mb-4 flex justify-center items-center text-white font-LeagueSpartan text-lg font-medium w-9 h-9 rounded-full bg-Irish">
                2
              </div>
              <h2 className="text-Irish font-bold md:font-semibold text-2xl md:text-3xl">
                Reflect
              </h2>
              <br />
              <div className="text-xl md:text-2xl text-Irish font-thin font-LeagueSpartan">
                <p>
                  Easily access the highlights and finest sections of the books
                  through a daily review and app for effortless reviewing.
                </p>
              </div>
            </div>
            <div className="w-full h-20 md:h-32" />
            <div className="flex justify-center items-center w-full">
              <img
                className="w-24 md:w-36"
                src="/assets/images/14.png"
                alt="Book"
              />
            </div>
          </div>
          <div className="p-5 flex flex-col">
            <div className="h-8 md:h-24 w-full" />
            <div className="flex-1">
              <div className="mb-4 flex justify-center items-center text-white font-LeagueSpartan text-lg font-medium w-9 h-9 rounded-full bg-Irish">
                3
              </div>
              <h2 className="text-Irish font-bold md:font-semibold text-2xl md:text-3xl">
                Remember
              </h2>
              <br />
              <div className="text-xl md:text-2xl text-Irish font-thin font-LeagueSpartan">
                <p>
                  Enhance your knowledge over time by reflecting on and
                  recalling what you've read, fostering continual growth.
                </p>
              </div>
            </div>
            <div className="w-full h-20 md:h-32" />
            <div className="flex justify-center items-center w-full">
              <img
                className="w-24 md:w-36"
                src="/assets/images/15.png"
                alt="Book"
              />
            </div>
          </div>
        </div>
      </Container>
      <ReadingSection />
      <RethinkSection />
      <ReadProgressSection />
      <NoteTakingSection />
      <FAQ />
      <Footer />
    </div>
  );
};

export default Home;
