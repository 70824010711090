import React from 'react';
import { Container } from 'shared/components';

export const RethinkSection = () => {
  return (
    <section className="bg-PurplyBlue py-20 md:py-40">
      <Container>
        <div className="flex justify-between">
          <div className="flex rotate-3 flex-1 justify-center md:-ml-16">
            <div className="w-64 hidden md:block -mr-40 z-20">
              <img
                className="max-w-full h-auto"
                src="/assets/images/mobile-2.png"
                alt="Mobile Rethink"
              />
            </div>
            <div className="w-64 hidden md:block -mt-3">
              <img
                className="max-w-full h-auto"
                src="/assets/images/mobile-5.png"
                alt="Mobile Rethink"
              />
            </div>
          </div>
          <div className="w-full md:w-[44%]">
            <h2 className="text-BluishCyan font-medium text-3xl md:text-6xl font-LeagueSpartan mb-12 md:mb-16">
            Elevate your Kindle highlights with AI-powered tools.
            </h2>
            <div className="w-full flex rotate-3 justify-center mb-12">
              <div className="w-32 block md:hidden z-10">
                <img
                  className="max-w-full h-auto"
                  src="/assets/images/mobile-2.png"
                  alt="Mobile Rethink"
                />
              </div>
              <div className="w-32 block md:hidden -ml-20 z-0">
                <img
                  className="max-w-full h-auto"
                  src="/assets/images/mobile-5.png"
                  alt="Mobile Rethink"
                />
              </div>
            </div>
            <div className="text-base md:text-3xl text-justify text-BluishCyan font-thin">
              <p>
              The <strong>2Read</strong> app now makes revisiting your highlights effortless. With WordWise, get context-aware definitions, and Highlight Insights provides AI-generated explanations to rediscover key passages. <br />
              <br /> Plus, AI Summary offers quick, comprehensive summaries of selected content.
              </p>
              <br />
              <p>
              Organize, manage, and get more from your Kindle reading like never before.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
