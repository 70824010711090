import React, { useState } from 'react';
import { Container } from '../Container';
import { Link } from 'react-router-dom';

export const Footer = () => {
  const [googleQr, setGoogleQr] = useState(false);
  const [AppleQr, setAppleQr] = useState(false);

  const showGoogleQr = () => setGoogleQr(true);
  const showAppleQr = () => setAppleQr(true);

  return (
    <footer className="pt-20 bg-gray-200">
      <Container>
        <div>
          <div className="flex flex-col xl:flex-row justify-between border border-Irish p-2 md:p-10 rounded-lg">
            <h2 className="text-Irish text-3xl md:text-7xl font-GentiumBookAsic xl:max-w-[520px] w-full mb-5 xl:mb-0">
              Start reading with Superpowers
            </h2>
            <div className="flex items-center justify-between md:justify-start gap-4">
              {AppleQr ? (
                <div className="flex flex-col justify-center gap-2">
                  <div className="w-[150px] h-[150px] md:w-[199px] md:h-[199px]">
                    <img
                      src="/assets/images/appstoreqr.png"
                      className="max-w-full h-auto"
                      alt="Qr Code"
                    />
                  </div>
                  <div className="flex justify-center pl-4">
                    <a className="text-black font-bold underline" href="https://apps.apple.com/us/app/2read-ai-kindle-reading-buddy/id6480341554">
                      Visit App Store
                    </a>
                    <img
                      width={18}
                      height={18}
                      src="/assets/images/Up.png"
                      alt="Arrow"
                    />
                  </div>
                </div>
              ) : (
                <button
                  onClick={showAppleQr}
                  className="bg-[#2B2D31] flex w-40 md:w-64 h-12 md:h-20 rounded-xl md:rounded-lg shadow-lg justify-center items-center gap-3 p-3 transition-transform duration-300 hover:scale-105"
                >
                  <img
                  className="w-5 h-5 md:w-auto md:h-auto"
                  src="/assets/images/AppleLogo.png"
                  alt="Apple"
                  />
                  <div className="text-white flex flex-col text-[8px] md:text-base">
                  <span className="font-Inter font-thin">DOWNLOAD FROM</span>
                  <span className="font-Inter font-medium ">
                    APPLE APP STORE
                  </span>
                  </div>
                </button>
              )}
              {googleQr ? (
                <div className="flex flex-col justify-center gap-2">
                  <div className="w-[150px] h-[150px] md:w-[199px] md:h-[199px]">
                    <img
                      src="/assets/images/playstoreqr.png"
                      className="max-w-full h-auto"
                      alt="Qr Code"
                    />
                  </div>
                  <div className="flex justify-center pl-4">
                    <a className="text-black font-bold underline" href="https://play.google.com/store/apps/details?id=com.randomoranges.highlights&pcampaignid=web_share">
                      Visit Play Store
                    </a>
                    <img
                      width={18}
                      height={18}
                      src="/assets/images/Up.png"
                      alt="Arrow"
                    />
                  </div>
                </div>
              ) : (
                <button
                  onClick={showGoogleQr}
                  className="bg-[#2B2D31] flex w-40 md:w-64 h-12 md:h-20 rounded-xl md:rounded-lg shadow-lg justify-center items-center gap-3 p-3 transition-transform duration-300 hover:scale-105"
                >
                  <img
                  className="w-5 h-5 md:w-auto md:h-auto"
                  src="/assets/images/GooglePlay.png"
                  alt="Google Play"
                  />
                  <div className="text-white flex flex-col text-[8px] md:text-base">
                  <span className="font-Inter font-thin">DOWNLOAD FROM</span>
                  <span className="font-Inter font-medium">
                    GOOGLE PLAY STORE
                  </span>
                  </div>
                </button>
              )}
            </div>
          </div>
            <a 
            className="flex justify-center mt-12" 
            href="https://www.producthunt.com/posts/2read-4?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-2read&#0045;4" 
            target="_blank"
            >
            <img 
              className="hover:bg-orange-600 hover:shadow-xl hover:shadow-orange-600/50 transition-all duration-300 rounded-[10px]"
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=695205&theme=dark" 
              alt="2Read&#0032; - AI&#0032;Kindle&#0032;Reading&#0032;Buddy | Product Hunt" 
              style={{ width: '250px', height: '54px' }} 
              width="250" 
              height="54" 
            />
            </a>
          <div className="py-24 font-LeagueSpartan text-xl text-center text-Irish">
            Made with ❤️ by 
            <a className="font-medium hover:underline" href="https://twitter.com/1TrueJishnu" target="_blank" rel="noopener noreferrer" aria-label="Xprofilelink"> 1truejishnu</a>
          </div>
          <div className="text-xs md:text-base items-center pb-3 flex justify-between font-Helvetica text-Irish">
            <div className="flex flex-col font-medium">
              <span>©2024 2Read.</span>
              <span>ALL RIGHTS RESERVED.</span>
            </div>
            <Link className="font-medium hover:underline" to="/terms">TERMS OF USE</Link>
            <Link className="font-medium hover:underline" to="/privacy">PRIVACY POLICY</Link>
          </div>
        </div>
      </Container>
    </footer>
  );
};
