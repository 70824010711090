import { Routes as Router, Route, BrowserRouter } from 'react-router-dom';
import { Layout } from 'shared/components';
import { HOME_PAGE_ROUTE } from 'shared/constants';
import HomePage from 'pages/home/Home';
import PrivacyPage from 'pages/Privacy';
import TermsOfUsePage from 'pages/TermsOfUse';

export const Routes = () => {
  return (
    <BrowserRouter>
      <Router>
        <Route path={HOME_PAGE_ROUTE}>
          {/* public routes */}
          <Route element={<Layout />}>
            <Route index element={<HomePage />} />
          </Route>
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsOfUsePage />} />

          {/* catch all */}
          <Route
            path="*"
            element={
              <div className="container mx-auto mt-9">
                <h3 className="text-lg text-center text-Kabul font-semibold">
                  Page doesn't exist
                </h3>
              </div>
            }
          />
        </Route>
      </Router>
    </BrowserRouter>
  );
};
