import { Container } from 'shared/components';

export const ReadProgressSection = () => {
  return (
    <section className="py-20 md:py-36 bg-OrangeYellow">
      <Container>
        <div className="flex text-justify">
          <div className="w-full md:w-[48%]">
            <h2 className="text-GreenCyan font-medium font-LeagueSpartan text-3xl md:text-6xl mb-10 md:mb-20">
              Track your reading progress, current reads, and future picks.
            </h2>
            <div className="w-full flex justify-center mb-14">
              <div className="w-32 block md:hidden">
                <img
                  className="max-w-full h-auto"
                  src="/assets/images/mobile-7.png"
                  alt="Reading Mobile"
                />
              </div>
            </div>
            <div className="text-base md:text-4xl text-GreenCyan font-thin font-LeagueSpartan">
              <p>
                Define your reading milestones, track how far you've come,
                organize your ongoing reads, and curate your list of future
                reads, conveniently centralized for easy management.
              </p>
            </div>
          </div>
          <div className="w-72 hidden md:block ml-[20%]">
            <img
              className="max-w-full h-auto"
              src="/assets/images/mobile-7.png"
              alt="Reading Mobile"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};
